'use strict';

angular.module('mvpcarrefourApp')
    .controller('SliderCtrl', function($scope, Product) {

        $scope.myInterval = 5000;
        $scope.noWrapSlides = false;
        $scope.active = 0;
        var currIndex = 0;

        function generateIndexesArray() {
            var indexes = [];
            for (var i = 0; i < currIndex; ++i) {
                indexes[i] = i;
            }
            return shuffle(indexes);
        }

        function shuffle(array) {
            var tmp, current, top = array.length;

            if (top) {
                while (--top) {
                    current = Math.floor(Math.random() * (top + 1));
                    tmp = array[current];
                    array[current] = array[top];
                    array[top] = tmp;
                }
            }

            return array;
        }
    })
    .controller('ContinueModalCtrl', ['$scope', '$rootScope', 'Product', '$uibModal', '$uibModalInstance', '$location', 'prod', 'Category', 'completedUrl', '$timeout', 'lifeStyleOption', 'idVote', 'Vote',
        function($scope, $rootScope, Product, $uibModal, $uibModalInstance, $location, prod, Category, completedUrl, $timeout, lifeStyleOption, idVote, Vote) {
            let totalVotes = $rootScope.totalVoteUser;
            $scope.countdown = 15 - totalVotes;
            $scope.productName = prod.title;


            let catNames = [];

            $scope.cancel = function() {

                //Cierre del modal

                $uibModalInstance.dismiss('cancel');

                //Cierre de la ficha del producto y eliminacion de la clase active
                $timeout(function() {
                    angular.element(document).find('.close-x').trigger('click');
                    angular.element('.single-product').removeClass("active");
                }, 500);
            }


            $scope.continue = function() {

                // Recuperamos las categorias, los productos votados y las subcategorias a las que pertenecen. Buscamos
                // las categorias correspondientes y se eliminan como opcion de votacion
                Category.list({}).then(categories => {
                    _.forEach(categories, c => {
                        catNames.push(c);
                        _.forEach($rootScope.votes, function(vote, key) {
                            _.forEach(c.children, sc => {
                                if (sc._id == vote.product.categories[0] && catNames.length > 1) {
                                    _.remove(catNames, function(n) {
                                        return n._id == c._id;
                                    });
                                }

                            })
                        });
                    })


                    // Si hay estilo de vida votado, se actualiza el voto
                    if (!angular.isUndefined($scope.vote)) {

                        let _updateVote = {
                            "user": $rootScope.currentUser._id,
                            "target": $scope.vote.target
                        }

                        // Actualizacion del voto
                        Vote.update(idVote, _updateVote).then(result => {

                            // Actualiza en el rootscope.votes el target del voto
                            _.forEach($rootScope.votes, v => {
                                if (!angular.isUndefined(_updateVote.target) && v._id == idVote) {
                                    v.target = _updateVote.target;
                                }

                            });

                        });

                    }

                    let name = catNames[_.random(0, catNames.length - 1)];
                    let href = $location.path('/votacion/' + name.slug);


                });



                //Cierre del modal
                $uibModalInstance.dismiss('cancel');

            }


            $scope.share = function(socialNetwork) {

                var encodedURL = completedUrl ? encodeURIComponent($location.$$absUrl) : encodeURIComponent($location.$$absUrl + "/" + prod.slug);

                var url = socialNetwork == 'facebook' ?
                    'https://www.facebook.com/sharer/sharer.php?u=' + encodedURL :
                    'https://twitter.com/intent/tweet?url=' + encodedURL;

                window.open(url, socialNetwork, 'width=550,height=550');
            }

            // Si el valor de lifeStyleOption es true, se mostraran en el modal los checks estilo de vida
            $scope.showLifeStyleOptions = function() {
                return lifeStyleOption;
            }
        }
    ])
